import { useLanguage } from '../../LanguageContext';
import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';
import step1Image from '../assets/images/step1-1.gif';
import step2Image from '../assets/images/step2-1.gif';
import step3Image from '../assets/images/step3-1.gif';
import step4Image from '../assets/images/step4-1.gif';

function HowItWorks() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const steps = [
        {
            image: step1Image,
            stepNumber: 'STEP 1',
            title: translations.step_1_title,
            descriptions: [
                translations.step_1_detail_1,
                translations.step_1_detail_2,
            ],
        },
        {
            image: step2Image,
            stepNumber: 'STEP 2',
            title: translations.step_2_title,
            descriptions: [
                translations.step_2_detail_1,
                translations.step_2_detail_2,
            ],
        },
        {
            image: step3Image,
            stepNumber: 'STEP 3',
            title: translations.step_3_title,
            descriptions: [
                translations.step_3_detail_1,
                translations.step_3_detail_2,
                translations.step_3_detail_3,
            ],
        },
        {
            image: step4Image,
            stepNumber: 'STEP 4',
            title: translations.step_4_title,
            descriptions: [
                translations.step_4_detail_1,
                translations.step_4_detail_2,
                translations.step_4_detail_3,
            ],
        },
    ];

    return (
        <div className="items-center w-full p-[5%] relative">
            <div className="max-w-[1440px] mx-auto">
                {/* Header Section */}
                <div className="flex flex-col text-[18px] mb-2">
                    <span className="text-omelet_purple">HOW IT WORKS</span>
                </div>
                <div className="flex flex-col text-[36px] mb-5">
                    {translations.how_oaasis_1}
                </div>
                <div className="flex flex-col text-[18px] text-gray-500 mb-16">
                    {translations.how_oaasis_2}
                </div>

                {/* Steps Section */}
                <div className="flex flex-col gap-[40px]">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className="flex flex-col xl:flex-row items-center gap-[40px] w-full"
                        >
                            {/* Image Section */}
                            <img
                                src={step.image}
                                alt={`Illustration for ${step.stepNumber}`}
                                className="w-full lg:w-[720px] h-auto border border-[rgba(0,0,0,0.08)] rounded-lg shadow-md"
                            />

                            {/* Text Section */}
                            <div className="w-full lg:w-[560px] flex flex-col gap-[8px]">
                                <div>
                                    <span className="text-omele©t_purple text-[18px]">
                                        {step.stepNumber}
                                    </span>
                                    <h3 className="text-[36px] text-[#222222]">
                                        {step.title}
                                    </h3>
                                </div>
                                <ul className="list-disc pl-5 text-[18px] text-[#667085] leading-[1.4]">
                                    {step.descriptions.map(
                                        (description, idx) => (
                                            <li key={idx}>{description}</li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Footer Section */}
                <div className="mt-12">
                    <hr className="w-full max-w-[1440px] bg-black h-[1px] border-none mx-auto" />
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
