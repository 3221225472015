import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import architecture_img from '../assets/images/architecture.svg';

function Architecture() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    return (
        <div className="items-center w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div className="w-full max-w-[1440px] mx-auto">
                <div className={`flex flex-col text-[18px] mb-2`}>
                    <span className="text-omelet_purple">
                        AI FOUNDATION MODEL
                    </span>
                </div>
                <div className={`flex flex-col text-[36px] mb-5`}>
                    {translations.architecture_1}
                </div>
                <div className={`flex flex-col text-[18px] text-gray-500`}>
                    {translations.architecture_2}
                </div>
                <div className={`flex flex-col text-[18px] text-gray-500`}>
                    {translations.architecture_3}
                </div>
                <div className="w-full max-w-[1440px] mx-auto">
                    <img
                        src={architecture_img}
                        alt="Architecture overview"
                        className="md:mt-40 xl:mt-0 w-full h-auto"
                    />
                </div>
            </div>
        </div>
    );
}

export default Architecture;
