import Button from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';

import { LanguageProvider } from '../../LanguageContext';

function TmsPricing() {
    const navigate = useNavigate();
    const go_back_bt = (
        <Button
            className="w-[200px] h-[50px] bg-gray2 rounded-[25px] font-light text-gray1 mx-auto hover:bg-omelet_violet hover:text-gray2"
            onClick={() => navigate('/tms')}
        >
            GO BACK
        </Button>
    );

    return (
        <LanguageProvider>
            <Header />
            <div className="bg-omelet_purple min-h-screen">
                <div className="grid grid-rows-3 items-center justify-center pt-[18%]">
                    <div className="text-gray2 text-[40pt] text-center">
                        COMING SOON
                    </div>
                    <div className="text-gray2 font-l text-[14pt] text-center">
                        contents will be updated later
                    </div>
                    <div className="justify-self-center">{go_back_bt}</div>
                </div>
            </div>
            <Footer />
        </LanguageProvider>
    );
}

export default TmsPricing;
