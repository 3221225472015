import Button from '../../components/ui/button';
import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icon } from '../assets/images/img_main_tms.svg';

function Banner() {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    return (
        // Full-width background container
        <div className="w-full bg-[#FAFAFA] px-[5%] py-[4%]">
            {/* Centered content wrapper */}
            <div
                className="
                    mx-auto h-full max-w-full 2xl:max-w-[1580px] xl:max-w-[1440px]
                    flex flex-col xl:flex-row items-start md:items-center justify-between overflow-hidden
                "
            >
                {/* Left Column: Text */}
                <div className="flex flex-col max-w-[600px] md:max-w-[500px] space-y-6">
                    <p
                        className="
                            font-light text-[16px] sm:text-[18px] leading-[160%] tracking-[-0.01em] text-[#667085]
                        "
                    >
                        {translations.title}
                    </p>

                    <p
                        className={`
                            font-bold leading-[150%] tracking-[-0.005em] text-[#101828]
                            ${
                                language === 'en'
                                    ? 'text-[22px] md:text-[25px] custom:text-[28px]'
                                    : 'text-[25px] md:text-[30px] custom:text-[34px]'
                            }
                        `}
                    >
                        {translations.banner}
                        <span className="text-omelet_purple">
                            {translations.banner_highlight}
                        </span>
                        {translations.banner_txt_2}
                    </p>

                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                        <Button
                            className="group relative transition-all w-full sm:w-[200px] h-[44px] bg-white text-omelet_purple text-[15px] flex items-center justify-center
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
                            onClick={() =>
                                window.open(
                                    'https://tms.oaasis.cc/login',
                                    '_blank',
                                    'noopener,noreferrer',
                                )
                            }
                        >
                            <span className="transition-transform duration-300 transform group-hover:-translate-x-5">
                                {translations.btn_trial}
                            </span>
                            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                                →
                            </span>
                        </Button>

                        <Button
                            className="group relative transition-all w-full sm:w-[200px] h-[44px] bg-omelet_purple text-white text-[15px] flex items-center justify-center
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
                            onClick={() => navigate('/tms/contact')}
                        >
                            <span className="transition-transform duration-300 transform group-hover:-translate-x-5">
                                {translations.btn_meeting}
                            </span>
                            <span className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                                →
                            </span>
                        </Button>
                    </div>
                </div>

                {/* Right Column: Icon (the image) */}
                <div className="mt-6 md:mt-0 md:ml-8 flex-shrink-0 max-w-full">
                    <Icon className="max-w-full xl:max-w-[720px] 2xl:max-w-[1080px] h-auto" />
                </div>
            </div>
        </div>
    );
}

export default Banner;
