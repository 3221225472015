import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

import how_img from '../assets/images/how_img.png';
import architecture_img from '../../tms/assets/images/architecture.svg';

function HowOaasis() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const fontClassTitle = 'font-MerriweatherSans font-l';

    return (
        <div className="items-center w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div className="w-full xl:w-[1280px] mx-auto">
                <div className={`flex flex-col ${fontClassTitle} text-[36px]`}>
                    <p>
                        {translations.how_oaasis_1}
                        <br />
                        {translations.how_oaasis_2}
                    </p>
                </div>
                <div className="mt-10 mb-14">
                    <img
                        src={how_img}
                        className=" md:mt-40 xl:mt-0 w-full h-auto"
                    />
                </div>
            </div>
        </div>
    );
}

export default HowOaasis;
