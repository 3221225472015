import { useLanguage } from '../../LanguageContext';

import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';

function ApiService() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const {
        apiservice,
        apiservice_txt_1,
        apiservice_txt_2,
        apiservice_txt_3,
        apiservice_txt_4,
        apiservice_txt_5,
        apiservice_txt_6,
        apiservice_txt_7,
        apiservice_txt_8,
    } = translations;

    return (
        <div className="items-center w-full px-[5%]">
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-20 mb-28 w-full xl:w-[1280px] mx-auto gap-y-10 sm:gap-y-0">
                {/* Title Section */}
                <div className="font-l text-[36px] text-center sm:text-left">
                    {apiservice}
                </div>

                {/* Content Section */}
                <div className="font-l text-[18px] text-center sm:text-left">
                    <div className="mb-5">
                        {apiservice_txt_1}
                        <span className="text-omelet_purple">
                            {apiservice_txt_2}
                        </span>
                        {apiservice_txt_3}
                        <span className="text-omelet_purple">
                            {apiservice_txt_4}
                        </span>
                        {apiservice_txt_5}
                    </div>
                    <div>
                        {apiservice_txt_6}
                        <span className="text-omelet_purple">
                            {apiservice_txt_7}
                        </span>
                        {apiservice_txt_8}
                    </div>
                </div>
            </div>
            <hr className="border-gray-200" />
        </div>
    );
}

export default ApiService;
