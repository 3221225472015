import React from 'react';
import { useLanguage } from '../../LanguageContext';
import en from '../assets/lang/en.json';
import ko from '../assets/lang/kor.json';
import ex_img_1 from '../assets/images/ex_1.svg';
import ex_img_2 from '../assets/images/ex_2.svg';
import ex_img_3 from '../assets/images/ex_3.svg';
import ex_img_4 from '../assets/images/ex_4.svg';
import ex_img_5 from '../assets/images/ex_5.svg';
import ex_img_6 from '../assets/images/ex_6.svg';

function ExclusiveFeatures() {
    const { language } = useLanguage();
    const translations = language === 'en' ? en : ko;

    const images = [ex_img_1, ex_img_2, ex_img_3, ex_img_4, ex_img_5, ex_img_6];

    return (
        <div className="flex items-center w-full pt-4 pb-8 px-4 md:px-8 lg:px-12">
            <div className="w-full max-w-[1440px] mx-auto">
                {/* Header Section */}
                <div className="flex flex-col text-[18px] mb-2">
                    <span className="text-omelet_purple">
                        EXCLUSIVE FEATURES
                    </span>
                </div>
                <div className="flex flex-col text-[36px] mb-5">
                    {translations.exclusive_features_title}
                </div>
                <div className="flex flex-col text-[18px] text-gray-500 mb-8">
                    {translations.exclusive_features_subtitle}
                </div>

                {/* Features Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 mb-16">
                    {images.map((imgSrc, index) => (
                        <FeatureCard
                            key={index}
                            imgSrc={imgSrc}
                            title={
                                translations[
                                    `exclusive_features_feat_${index + 1}`
                                ]
                            }
                            subtitle={
                                translations[
                                    `exclusive_features_sub_feat_${index + 1}`
                                ]
                            }
                        />
                    ))}
                </div>

                {/* Divider */}
                <hr className="w-full max-w-[1440px] bg-black h-[1px] mx-auto border-0" />
            </div>
        </div>
    );
}

function FeatureCard({ imgSrc, title, subtitle }) {
    return (
        <div className="flex flex-col items-start max-w-[460px] rounded-lg shadow-md border border-gray-200 overflow-hidden hover:shadow-lg transition-shadow">
            {/* Image Section */}
            <div className="w-full aspect-w-16 aspect-h-9 bg-gray-100">
                <img
                    src={imgSrc}
                    alt={title}
                    className="object-cover w-full h-full"
                    loading="lazy"
                />
            </div>

            {/* Text Section */}
            <div className="flex flex-col gap-2 p-4">
                <h3 className="text-[24px] font-medium text-gray-900">
                    {title}
                </h3>
                <p className="text-[18px] font-normal text-gray-600">
                    {subtitle}
                </p>
            </div>
        </div>
    );
}

export default ExclusiveFeatures;
