import { useLanguage } from '../../../LanguageContext';

function CardWhyOaasis({ image_url, title, details }) {
    const { language } = useLanguage();

    const fontClassTitle =
        language === 'en'
            ? 'font-MerriweatherSans font-m'
            : 'font-NotoSansKR font-semibold';
    const fontClassText =
        language === 'en'
            ? 'font-MerriweatherSans font-l text-[18px]'
            : 'font-NotoSansKR text-[18px]';

    const containerHeight =
        language === 'en'
            ? 'h-[595px] 2xl:h-[637px]'
            : 'h-[575px] 2xl:h-[525px]';

    return (
        <div
            className={`flex flex-col mb-10 max-w-[370px] 2xl:max-w-[450px] ${containerHeight} rounded-[10px] bg-white shadow-[0_3px_8px_0_rgba(0,0,0,0.1)] group overflow-hidden hover:shadow-[0_10px_10px_0_rgba(0,0,0,0.2)]`}
        >
            {/* 이미지 섹션 */}
            <div className="w-full aspect-w-4 aspect-h-3 overflow-hidden">
                <img
                    src={image_url}
                    alt=""
                    className="w-full h-full object-cover object-center transition-all"
                />
            </div>

            {/* 텍스트 섹션 */}
            <div className="flex flex-col flex-grow pr-5 pl-5 pb-5">
                <p
                    className={` ${fontClassTitle} text-[28px] text-black mt-3 `}
                >
                    {title}
                </p>
                <p
                    className={` ${fontClassText} text-start mt-2 text-[#6D6D6D] `}
                >
                    {details}
                </p>
            </div>
        </div>
    );
}

export default CardWhyOaasis;
