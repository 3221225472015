import './global.css';
import oaasis from './assets/images/omelet_oaasis.webp';

import Header from './components/header';
function Main() {
    return (
        <div className="flex flex-col items-center min-h-screen bg-white">
            <Header />
            <p className="mt-4 text-center text-gray-600 font-bold text-[34px]">
                This is a temporary page that shows omelet in an oasis!
            </p>
            <img
                src={oaasis}
                alt="Oaasis Image"
                className="mt-4 w-1/2 h-auto rounded-lg"
            />
        </div>
    );
}

export default Main;
