import './global.css';

import Header from './routing/components/header';
import Banner from './routing/components/banner';
import ApiService from './routing/components/apiservice';
import WhyOaasis from './routing/components/why-oaasis';
import HowOaasis from './routing/components/how-oaasis';
import Footer from './routing/components/footer';

function MainRouting() {
    return (
        <div className="font-MerriweatherSans flex flex-col items-center min-h-screen bg-white">
            <Header />
            <Banner />
            <div className="w-full">
                <ApiService />
                <WhyOaasis />
                <HowOaasis />
            </div>
            <Footer />
        </div>
    );
}

export default MainRouting;
