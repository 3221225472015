import './global.css';

import Header from './tms/components/header';
import Banner from './tms/components/banner';
import WhyOaasis from './tms/components/why-oaasis';
import Architecture from './tms/components/architecture';
import Footer from './tms/components/footer';
import ExclusiveFeatures from './tms/components/ex-features';
import HowItWorks from './tms/components/how-it-works';

function MainTms() {
    return (
        <div className="font-MerriweatherSans flex flex-col items-center min-h-screen bg-white">
            <Header />
            <Banner />
            <div className="w-full">
                <WhyOaasis />
                <HowItWorks />
                <ExclusiveFeatures />
                <Architecture />
            </div>
            <Footer />
        </div>
    );
}

export default MainTms;
